import {Store} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Variants} from "../../Utils/Emuns/NotificationsVariants";

import {setErrors} from "../../Reducers/Errors"

const NotificationTitle = ({title, dateTime}) => {
    return (
        <span>
            <FontAwesomeIcon icon={faTriangleExclamation} className="feather me-1 text-warning"/>{title}&nbsp;&nbsp;
            <span className="fs--2 text-yellow">{dateTime}</span>
        </span>
    )
}

const ErrorNotification = () => {

    const dispatch = useDispatch()

    const position = 'bottom-left'
    const variant = Variants.DANGER;
    const messages = useSelector(state => state.Errors.data)
    // const authMessages = useSelector(state => state.Auth2.errors)
    const usersMessages = useSelector(state => state.Users.errors)
    const blogMessages = useSelector(state => state.Blog.errors)
    const orderDataMessages = useSelector(state => state.OrderData.errors)

    let allMessages = messages
        // .concat(authMessages)
        .concat(usersMessages)
        .concat(blogMessages)
        .concat(orderDataMessages)

    useEffect(() => {
        allMessages.map(el =>
            Store.addNotification({
                title: <NotificationTitle title={'Помилка!'} dateTime={el.dateTime}/>,
                message: el.message,
                type: variant,
                insert: "top",
                container: position,
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                    showIcon: true,
                }
            })
        )
        if (messages.length > 0)
            dispatch(setErrors([]))

    }, [allMessages]);
    return (<></>)
}

export default ErrorNotification;