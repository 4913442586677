import '@fortawesome/fontawesome-svg-core/styles.css'
import {useDispatch} from "react-redux";
import {useContext, useEffect} from "react";
import AuthContext from "../../Components/AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import {
    setShipmentByDays,
    setNextOrder
} from "../../Reducers/Dashboard";
import {setErrors} from "../../Reducers/Errors";
import ShipmentByDays from "./Dashboard/ShipmentByDays";
import {handleError} from "../../Utils/Errors";
import LastNews from "./Dashboard/LastNews";
import InfoManagers from "./Dashboard/InfoManagers";
import NextOrder from "./Dashboard/NextOrder";


const Main = () => {

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
    const dispatch = useDispatch()

    useEffect(() => {
        axios.get(`${baseUrl}/orders/dashboard/`,
            {
                timeout: 5000,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-CSRFToken": Cookies.get("csrftoken")
                }
            }).then(function (response) {

            if (response.status === 200) {

                const responseData = response.data;

                switch (responseData.status) {
                    case "SUCCESS":
                        dispatch(setShipmentByDays(responseData.data.shipment_by_days))
                        dispatch(setNextOrder(responseData.data.next_order))
                        break;
                    default:
                        dispatch(setErrors(['Невідома помилка. Повторіть спробу пізніше.']))
                }
            }
        }).catch(function (error) {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        })
    }, [dispatch]);

    return (
        <div className="container-xxl">
            {/*Header tittle*/}
            <div className="hk-pg-header pg-header-wth-tab pt-7">
                <div className="d-flex">
                    <div className="d-flex flex-wrap justify-content-between flex-1">
                        <div className="mb-lg-0 mb-2 me-8">
                            <h1 className="pg-title">Особистий кабінет</h1>
                            <p>Електронних послуг компанії "Віконний стандарт"</p>
                        </div>
                    </div>

                    {/*<div className="d-flex flex-wrap justify-content-end flex-1">*/}
                    {/*    <div className="mb-lg-0 mb-0 me-0">*/}
                    {/*        <h5 className="text-primary">ТОВ "Наша Компанія Дилер"</h5>*/}
                    {/*        <p>Кучер Дмитро</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
            {/*Header tittle*/}

            <div className="hk-pg-body">

                <div className="row mb-3">
                    <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                        <NextOrder/>
                    </div>
                    <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                        <ShipmentByDays/>
                    </div>
                    <div className="col-xxl-4 col-lg-4 col-md-4 mb-md-4 mb-3">
                        <LastNews/>
                    </div>
                </div>

                <div className="row mb-3">
                    <InfoManagers/>
                </div>

            </div>
        </div>
    )
}

export default Main