import { useState, useRef, useCallback, useEffect } from 'react';


const useRecaptcha = () => {
  const [capchaToken, setCapchaToken] = useState('');
  const recaptchaRef = useRef(false);

  const handleRecaptcha = useCallback((token) => {
    setCapchaToken(token || '');
    recaptchaRef.current = !recaptchaRef.current;
  }, []);

  const refreshCaptcha = () => {
      if (recaptchaRef.current && capchaToken) {
        recaptchaRef.current?.reset();
        setCapchaToken('');
      }
    };

  useEffect(() => {

    let tokenRefreshTimeout = 0;

    if (capchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
      }
    };
  }, [capchaToken]);

  return { capchaToken, setCapchaToken, recaptchaRef, handleRecaptcha , refreshCaptcha};
};

export default useRecaptcha;