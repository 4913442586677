const {formatError} = require("./Errors");
const locale = "uk-UA"

function emptyOrValue(value, fixed = 2) {
    // value.toFixed(fixed)
    const nFormat = new Intl.NumberFormat(locale,
        {
            minimumFractionDigits: fixed,
            useGrouping: true,
        });
    return value > 0 ? nFormat.format(value) : "";
}

function emptyOrZero(value, fixed = 2) {

    fixed = 0 ? fixed < 0 : fixed

    const nFormat = new Intl.NumberFormat(locale,
        {
            minimumFractionDigits: fixed,
            useGrouping: true,
        });

    let zeroString = "0";

    if (fixed > 0) {
        zeroString = "0," + Array(fixed).fill(0).join('')
    }

    return value > 0 ? nFormat.format(value) : zeroString;
}

function emptyDate(value) {
    return value.length > 0 ? value : "00010101";
}

function emptyOderNumber(value) {
    return value.length > 0 ? value : "0";
}

function validatePass(password1, password2) {

    let errors = []

    if (password1 === password2) {
        let validationRegex = [
            {regex: /.{8,}/, error: 'Ваш пароль повинен містити як мінімум 8 символів'}, // min 8 letters,
            {regex: /[0-9]/, error: 'Ваш пароль повинен містити як мінімум 1 цифру'}, // numbers from 0 - 9
            {regex: /[a-z]/, error: 'Ваш пароль повинен містити як мінімум 1 маленьку літеру'},// letters from a - z (lowercase)
            {regex: /[A-Z]/, error: 'Ваш пароль повинен містити як мінімум 1 велику літеру'},// letters from A-Z (uppercase),
            {regex: /[#@$&%]/, error: 'Ваш пароль повинен містити як мінімум 1 символ # @ $ & %'} // special characters
        ]

        validationRegex.forEach((item, i) => {
            let isValid = item.regex.test(password1);
            if (!isValid) {
                errors.push(formatError(item.error))
            }
        })
    } else errors.push(formatError("Паролі не співпадають"))

    return errors
}

module.exports = {
    emptyOrValue,
    emptyOrZero,
    emptyDate,
    emptyOderNumber,
    validatePass,
}